import {
  useEffect,
  useRef,
  useCallback,
  ReactNode,
  useState,
  MouseEvent,
} from "react";

import {
  faCheck,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Box,
  Center,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { useMultiSelect } from "hooks/useMultiSelect";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { Tooltip } from "../Tooltip";
import { Item } from "./Item";

import "react-loading-skeleton/dist/skeleton.css";

const WHITE_INSET_SHADOW =
  "inset 0px 10px 10px -10px rgb(255 255 255 / 15%), inset 0px -10px 10px -10px rgb(255 255 255 / 15%)";
const BLACK_INSET_SHADOW =
  "inset 0px 10px 10px -10px rgb(0 0 0 / 15%), inset 0px -10px 10px -10px rgb(0 0 0 / 15%)";

export type ItemMenuData = {
  name: string;
  id: number;
  color?: string;
  bottomInfo?: string;
  leftIcon?: ReactNode;
  leftImageSrc?: string;
  hidden?: boolean;
};

export type MultiSelectAction = (ids: number[], reset: () => void) => void;

type ItemMenuProps = {
  data?: ItemMenuData[];
  onItemRightButtonClick: (id: number) => void;
  onItemClick?: (id: number) => void;
  selectedItemId?: number;
  hiddenItemIds: number[];
  bottomComponent?: ReactNode;
  onFilterInput?: (text: string) => void;
  topSubmenuComponent?: ReactNode;
  loading: boolean;
  /**
   * Placeholder element that is used in case there is no data in the list
   */
  placeholder: JSX.Element;
  onExportToGPXclick?: MultiSelectAction;
  onDeleteClick?: MultiSelectAction;
  onMultiSelectModeOn?: () => void;
};
export const ItemMenu = ({
  data,
  onItemClick,
  onItemRightButtonClick,
  selectedItemId,
  hiddenItemIds,
  bottomComponent,
  loading = false,
  placeholder,
  onFilterInput,
  topSubmenuComponent,
  onExportToGPXclick,
  onDeleteClick,
  onMultiSelectModeOn,
}: ItemMenuProps) => {
  const { scrollIntoView, targetRef, scrollableRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 40,
      axis: "y",
      duration: 500,
    });
  const theme = useMantineTheme();
  const isDark = theme.colorScheme === "dark";
  const [isSelectMode, setIsSelectMode] = useState(false);
  const wasSelectedInMenu = useRef(false);
  const { t } = useTranslation();
  const { onMultiselectClick, selectedIds, reset, setNewData } = useMultiSelect(
    data?.map((value) => ({ id: value.id, selected: false })) ?? []
  );
  const dataRef = useRef(data);
  useEffect(() => {
    dataRef.current = data;
    setNewData(data?.map((value) => ({ id: value.id, selected: false })) ?? []);
  }, [data, setNewData]);

  useEffect(() => {
    if (isSelectMode === false) reset();
  }, [isSelectMode, reset]);

  useEffect(() => {
    if (wasSelectedInMenu.current) {
      wasSelectedInMenu.current = false;
      return undefined;
    }
    if (selectedItemId === undefined) return undefined;
    const timeout = setTimeout(() => {
      scrollIntoView();
    }, 100);
    return () => clearTimeout(timeout);
  }, [selectedItemId, scrollIntoView]);

  const handleClick = useCallback(
    (e: MouseEvent<Element>, index: number) => {
      if (isSelectMode) {
        const wasShift = e.nativeEvent.shiftKey;
        onMultiselectClick(index, wasShift);
      } else {
        const id = dataRef.current?.[index]?.id;
        wasSelectedInMenu.current = true;
        if (id) onItemClick?.(id);
      }
    },
    [isSelectMode, onItemClick, onMultiselectClick]
  );

  const handleExportToGPXclick = () => {
    if (selectedIds.length > 0) {
      onExportToGPXclick?.(selectedIds, reset);
      return;
    }
    if (selectedItemId) {
      onExportToGPXclick?.([selectedItemId], reset);
    }
  };

  const handleDeleteClick = () => {
    if (selectedIds.length > 0) {
      onDeleteClick?.(selectedIds, reset);
      return;
    }
    if (selectedItemId) {
      onDeleteClick?.([selectedItemId], reset);
    }
  };

  const handleMultiSelectIconClick = () => {
    setIsSelectMode((prev) => !prev);
    onMultiSelectModeOn?.();
  };

  const getActionIconColor = () => {
    if (theme.colorScheme === "dark") {
      return isSelectMode ? "primaryColor" : "gray";
    }
    return isSelectMode ? "primaryColor" : "dark";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        flexGrow: 1,
        minHeight: 0,
      }}
    >
      {/* A nested ternary: if loading it displays a loader, if there is no data it displays emptyMessage, if there is data it displays it */}
      {loading ? (
        <Box
          p="sm"
          sx={{
            maxHeight: "100%",
            overflow: "hidden",
            "&:after": {
              content: '""',
              position: "absolute",
              zIndex: 1,
              bottom: 0,
              left: 0,
              pointerEvents: "none",
              width: "100%",
              height: "50%",
              background: isDark
                ? "linear-gradient(0deg, black, transparent)"
                : "linear-gradient(0deg, white, transparent)",
            },
          }}
        >
          <Skeleton
            count={30}
            baseColor={isDark ? theme.colors.dark[5] : theme.colors.gray[3]}
            highlightColor={
              isDark ? theme.colors.dark[3] : theme.colors.gray[1]
            }
          />
        </Box>
      ) : data === undefined || data.length === 0 ? (
        placeholder
      ) : (
        <Stack
          spacing={0}
          sx={{ flexGrow: 1, position: "relative", overflow: "hidden" }}
        >
          <Group sx={{ flexWrap: "nowrap" }} py={4} px={13} spacing={2}>
            <Group sx={{ flexGrow: 1, flexWrap: "nowrap" }} spacing={2}>
              <TextInput
                placeholder={t("itemlist.filter_by_name_placeholder")}
                sx={{ flexGrow: 1 }}
                size="xs"
                onChange={(e) => onFilterInput?.(e.target.value)}
              />
              {topSubmenuComponent}
            </Group>
            <Center sx={{ width: 28, height: 28 }}>
              <Tooltip label={t("itemlist.multiselect_tooltip")}>
                <ActionIcon
                  size="sm"
                  onClick={handleMultiSelectIconClick}
                  radius={9999}
                  color={getActionIconColor()}
                  variant={isSelectMode ? "filled" : "outline"}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </ActionIcon>
              </Tooltip>
            </Center>
          </Group>
          <ScrollArea
            viewportRef={scrollableRef}
            style={{ height: "100%" }}
            styles={{
              thumb: {
                "&:before": { minWidth: 0 },
              },
              viewport: {
                boxShadow: isDark ? WHITE_INSET_SHADOW : BLACK_INSET_SHADOW,
                "& > div": {
                  display: "block!important",
                },
              },
            }}
          >
            <Box
              sx={{
                "&>div:not(:last-child)": {
                  borderBottomWidth: 1,
                  borderBottomColor: isDark
                    ? theme.colors.dark[4]
                    : theme.colors.gray[3],
                  borderBottomStyle: "solid",
                },
              }}
            >
              {data.map(
                (
                  {
                    id,
                    hidden,
                    color,
                    leftIcon,
                    leftImageSrc,
                    bottomInfo,
                    name,
                  },
                  index
                ) => (
                  // hiding via display:none shows way better performance than mounting/unmounting
                  <div
                    key={id}
                    style={hidden ? { display: "none" } : undefined}
                  >
                    <Item
                      index={index}
                      leftIcon={leftIcon}
                      leftImageSrc={leftImageSrc}
                      bottomInfo={bottomInfo}
                      key={id}
                      id={id}
                      name={name}
                      onClick={handleClick}
                      onRightButtonClick={onItemRightButtonClick}
                      selected={id === selectedItemId}
                      hidden={hiddenItemIds.includes(id)}
                      ref={id === selectedItemId ? targetRef : undefined}
                      isSelectMode={isSelectMode}
                      isMultiselected={selectedIds.includes(id)}
                      trackIconColor={color}
                    />
                  </div>
                )
              )}
            </Box>
          </ScrollArea>
          <Group p="xs" spacing="xs">
            <Box sx={{ flexGrow: 1 }}>
              <Text weight={isSelectMode ? 700 : 400} size="sm">
                {isSelectMode
                  ? `${selectedIds.length} / ${data?.length}`
                  : `${t("itemlist.count_label")}: ${data?.length || 0}`}
              </Text>
            </Box>
            <Tooltip label={t("itemlist.export_gpx_button_tooltip")}>
              <ActionIcon
                disabled={selectedIds.length === 0 && !selectedItemId}
                onClick={handleExportToGPXclick}
                variant="filled"
                color="primaryColor"
              >
                <FontAwesomeIcon icon={faDownload} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label={t("itemlist.delete_button_tooltip")}>
              <ActionIcon
                onClick={handleDeleteClick}
                disabled={selectedIds.length === 0 && !selectedItemId}
                color="red"
                variant="filled"
              >
                <FontAwesomeIcon icon={faTrash} />
              </ActionIcon>
            </Tooltip>
          </Group>
          {bottomComponent && <Stack p="xs">{bottomComponent}</Stack>}
        </Stack>
      )}
    </Box>
  );
};
