import { useCallback, useEffect, useRef } from "react";

import { useLeafletContext } from "@react-leaflet/core";
import { MAX_ZOOMS, TANGRAM_ATTRIBUTION } from "config/constants";
import { Language } from "config/enums/languages";
import { MapID } from "config/enums/mapIDs";
import { Overlay } from "config/enums/overlays";
import L from "leaflet";
import { useOptions } from "stores/optionsStore/OptionsContext";
// NOTE: we use a modified Tangram lib that's just a copy of what's in node_modules with
// added stuff for displaying lat/lng in point feature data
import Tangram from "lib/tangram/tangram.min.mjs";
import _ from "lodash";

import "./TangramTileLayer.css";
import { useMantineTheme } from "@mantine/core";

const baseURL = import.meta.env.BASE_URL;

type SceneMeta = {
  id: number;
  name: string;
  label: string;
  isAnimated: boolean;
  hasLabels: boolean;
  colors?: { text: string; name: string }[];
  extraSupportedOverlays?: Overlay[];
};

const TANGRAM_LAYERS_META: SceneMeta[] = [
  {
    id: MapID.basic,
    name: "basic",
    label: "Basic",
    isAnimated: false,
    hasLabels: false,
  },
  {
    id: MapID.bubbleWrap,
    name: "bubble-wrap",
    label: "Bubble-wrap",
    isAnimated: false,
    hasLabels: true,
  },
  {
    id: MapID.cinnabar,
    name: "cinnabar",
    label: "Cinnabar",
    isAnimated: false,
    hasLabels: true,
  },
  {
    id: MapID.refill,
    name: "refill",
    label: "Refill",
    isAnimated: false,
    hasLabels: true,
    colors: [
      { text: "Black", name: "black" },
      { text: "Blue", name: "blue" },
      { text: "Blue-gray", name: "blue-gray" },
      { text: "Brown-orange", name: "brown-orange" },
      { text: "High contrast", name: "high-contrast" },
      { text: "Gray", name: "gray" },
      { text: "Gray-gold", name: "gray-gold" },
      { text: "Inverted", name: "inverted" },
      { text: "Inverted-dark", name: "inverted-dark" },
      { text: "Pink", name: "pink" },
      { text: "Pink-yellow", name: "pink-yellow" },
      { text: "Purple-green", name: "purple-green" },
      { text: "Sepia", name: "sepia" },
      { text: "Zinc", name: "zinc" },
    ],
  },
  {
    id: MapID.street,
    name: "street",
    label: "Street",
    isAnimated: false,
    hasLabels: false,
  },
  {
    id: MapID.topo,
    name: "topo",
    label: "Topo",
    isAnimated: false,
    hasLabels: false,
  },
  {
    id: MapID.tron,
    name: "tron",
    label: "Tron",
    isAnimated: true,
    hasLabels: true,
  },
  {
    id: MapID.walkabout,
    name: "walkabout",
    label: "Walkabout",
    isAnimated: false,
    hasLabels: true,
    extraSupportedOverlays: [Overlay.bikePaths, Overlay.walkPaths],
  },
];

const DEFAULT_TANGRAM_SETTINGS = {
  sdk_bike_network_overlay: false,
  sdk_bike_overlay: false,
  sdk_path_overlay: true,
  sdk_path_shields: true,
  sdk_road_shields: true,
  sdk_transit_overlay: false,
  sdk_building_extrude: false,
  ux_language: Language.en,
};

type MapLabelsCount = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

/**
 * Calculates tangrams label count from percentage (0%-100%) ---> (0 - 11 integers)
 */
const calculateLabelCountValueFromPercentage = (percentage: number) => {
  const recalculated = Math.floor(11 * 0.01 * percentage);
  if (recalculated > 11) return 11;
  if (recalculated < 0) return 0;
  return recalculated as MapLabelsCount;
};

type TangramLayerProps = {
  sceneMeta: SceneMeta;
  buildingExtrude: boolean;
};
export const TangramLayer = ({
  sceneMeta,
  buildingExtrude,
}: TangramLayerProps) => {
  // It would be awesome to have this typed but according to Tangram's GitHub,
  // it's a Leaflet Layer extended with bunch of stuff and it would take lot of time to type.
  // And since Tangram lib is most probably abandoned, that time would be pretty much wasted.
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const layerRef = useRef<any | null>(null);
  const context = useLeafletContext();
  const {
    state: { mapColor, mapOverlays: overlayType, language, mapLabels },
  } = useOptions();
  const theme = useMantineTheme();

  const updateConfigRef = useRef<() => void>();
  const popupRef = useRef<L.Popup>();
  const showPopupTimerRef = useRef<NodeJS.Timeout>();
  const hidePopupTimerRef = useRef<NodeJS.Timeout>();
  const upcomingFeatureLatLngRef = useRef<number[]>();

  useEffect(() => {
    popupRef.current = L.popup({
      closeButton: false,
      className: `poi-popup ${theme.colorScheme === "dark" ? "dark" : "light"}`,
    });
  }, [theme.colorScheme]);

  const updateConfig = useCallback(() => {
    if (!layerRef.current?.scene?.config?.global) return;
    const { scene } = layerRef.current;
    const { global } = scene.config;
    global.sdk_transit_overlay = overlayType.includes(Overlay.transit);
    global.sdk_road_shields = !overlayType.includes(Overlay.transit);
    global.sdk_bike_overlay = overlayType.includes(Overlay.bikePaths);
    global.sdk_bike_network_overlay = overlayType.includes(Overlay.bikePaths);
    global.sdk_path_overlay = overlayType.includes(Overlay.walkPaths);
    global.sdk_path_shields = overlayType.includes(Overlay.walkPaths);
    global.sdk_biking_lines = overlayType.includes(Overlay.bikeTrails);
    global.sdk_walking_lines = overlayType.includes(Overlay.hikeWaymarked);
    global.sdk_animated = sceneMeta.isAnimated;
    global.sdk_building_extrude = buildingExtrude;
    global.ux_language = language;
    scene.updateConfig();
  }, [buildingExtrude, language, overlayType, sceneMeta.isAnimated]);

  useEffect(() => {
    updateConfigRef.current = updateConfig;
  }, [updateConfig]);

  const constructImportArray = useCallback(() => {
    const mapLabelsCount = calculateLabelCountValueFromPercentage(mapLabels);
    const pathBase = `${baseURL}map/scenes/${sceneMeta.name}/`;
    const scenePath = `${pathBase + sceneMeta.name}-style.yaml`;
    const labelsPath = `${pathBase}themes/label-${mapLabelsCount}.yaml`;
    const dataToImport = [scenePath];
    if (sceneMeta?.hasLabels) {
      dataToImport.push(labelsPath);
    }
    const colorChoices = sceneMeta?.colors;
    if (colorChoices && colorChoices.length > 0 && mapColor) {
      const colors = `${pathBase}themes/color-${mapColor}.yaml`;
      dataToImport.push(colors);
    }
    return dataToImport;
  }, [
    mapColor,
    mapLabels,
    sceneMeta?.colors,
    sceneMeta?.hasLabels,
    sceneMeta.name,
  ]);

  const pathBase = `/map/scenes/${sceneMeta.name}/`;
  const scenePath = `${pathBase + sceneMeta.name}-style.yaml`;
  const labelsPath = `${pathBase}themes/label-11.yaml`;
  const dataToImport = [scenePath];

  if (sceneMeta?.hasLabels) {
    dataToImport.push(labelsPath);
  }

  const colorChoices = sceneMeta?.colors;
  if (colorChoices && colorChoices.length > 0 && mapColor) {
    const colors = `${pathBase}themes/color-${mapColor}.yaml`;
    dataToImport.push(colors);
  }

  const setShowPopupTimer = useCallback(
    (name: string | undefined, kind: string, latLng: number[], map: L.Map) => {
      showPopupTimerRef.current = setTimeout(() => {
        const formattedKindText = _.capitalize(kind.replaceAll("_", " "));

        const content =
          name !== undefined
            ? `<p>${name}</p><p>${formattedKindText}</p>`
            : `<p>${formattedKindText}</p>`;

        if (popupRef.current) {
          popupRef.current
            .setLatLng(L.latLng(latLng[0], latLng[1]))
            .setContent(content);

          popupRef.current.openOn(map);
          showPopupTimerRef.current = undefined;
        }
      }, 750);
    },
    [popupRef.current]
  );

  const setHidePopupTimer = useCallback(() => {
    hidePopupTimerRef.current = setTimeout(() => {
      if (popupRef.current) {
        popupRef.current.remove();
        hidePopupTimerRef.current = undefined;
      }
    }, 250);
  }, [popupRef.current]);

  useEffect(() => {
    layerRef.current = Tangram.leafletLayer({
      scene: {
        import: constructImportArray(),
        global: DEFAULT_TANGRAM_SETTINGS,
      },
      attribution: TANGRAM_ATTRIBUTION,
      maxZoom: MAX_ZOOMS.tangram,
    });
    if (layerRef.current) {
      layerRef.current.scene.subscribe({
        load: () => {
          updateConfigRef.current?.();
        },
      });
    }
  }, []);

  useEffect(() => {
    const container = context.layerContainer || context.map;
    container.addLayer(layerRef.current);

    layerRef.current.setSelectionEvents(
      {
        hover: function (selection: Record<string, any>) {
          if (popupRef.current !== undefined && container instanceof L.Map) {
            const isPopupOpen = popupRef.current.isOpen();

            // We are hovering over a POI feature
            if (
              selection.feature !== undefined &&
              selection.feature.source_layer === "pois" &&
              selection.feature.latLng !== undefined
            ) {
              const popupLatLng = popupRef.current.getLatLng();

              const name = selection.feature.properties.name;
              const kind = selection.feature.properties.kind;
              const featureLatLng = selection.feature.latLng;

              // We have an active popup
              if (isPopupOpen) {
                // Edge case, when there is an active popup but the user moves quickly
                // to another feature that is so close there are is no gap between them
                if (
                  popupLatLng?.lat !== featureLatLng[0] ||
                  popupLatLng?.lng !== featureLatLng[1]
                ) {
                  popupRef.current.remove();
                  upcomingFeatureLatLngRef.current = featureLatLng;
                  setShowPopupTimer(name, kind, featureLatLng, container);
                } else {
                  // It's the same feature, we do nothing
                }
              } else if (showPopupTimerRef.current) {
                 // Edge case, when there is a show timer running but the user
                 // is already hovering over a different feature
                if (
                  upcomingFeatureLatLngRef.current !== undefined &&
                  (upcomingFeatureLatLngRef.current[0] !== featureLatLng[0] ||
                    upcomingFeatureLatLngRef.current[1] !== featureLatLng[1])
                ) {
                  clearTimeout(showPopupTimerRef.current);
                  showPopupTimerRef.current = undefined;
                  upcomingFeatureLatLngRef.current = featureLatLng;
                  setShowPopupTimer(name, kind, featureLatLng, container);
                } else {
                  // It's the same feature, we do nothing
                }
              } else {
                // Show popup for hovered-on feature
                upcomingFeatureLatLngRef.current = featureLatLng;
                setShowPopupTimer(name, kind, featureLatLng, container);
              }
            } else if (showPopupTimerRef.current) {
              // There is no feature being hovered on but we have an active show timer, let's cancel it
              clearTimeout(showPopupTimerRef.current);
              showPopupTimerRef.current = undefined;
            } else if (isPopupOpen && !hidePopupTimerRef.current) {
              // No feature is being hovered on, let's hide the popup
              setHidePopupTimer();
            }
          }
        },
      },
      { radius: 5 }
    );

    return () => {
      if (layerRef.current) {
        container.removeLayer(layerRef.current);
      }
    };
  }, [
    hidePopupTimerRef.current,
    popupRef.current,
    setHidePopupTimer,
    setShowPopupTimer,
    showPopupTimerRef.current,
    upcomingFeatureLatLngRef.current,
  ]);

  useEffect(() => {
    if (!layerRef.current) return;
    const { scene } = layerRef.current;
    scene.load({ import: constructImportArray() });
  }, [constructImportArray, layerRef.current, sceneMeta.colors, mapLabels]);

  useEffect(() => {
    updateConfig();
  }, [
    overlayType,
    language,
    buildingExtrude,
    sceneMeta.isAnimated,
    updateConfig,
  ]);

  return null;
};

export const TANGRAM_LAYERS_LABELS = TANGRAM_LAYERS_META.map((layer) => ({
  label: layer.label,
  value: layer.id,
}));

export const isTangramLayer = (id: number) =>
  TANGRAM_LAYERS_META.some((layer) => layer.id === id);

export const getTangramLayerMeta = (id: number) =>
  TANGRAM_LAYERS_META.find((layer) => layer.id === id);

export const getTangramLayer = (id: number) => {
  const meta = getTangramLayerMeta(id);
  if (!meta) return null;
  return (
    <TangramLayer key={meta.name} buildingExtrude={false} sceneMeta={meta} />
  );
};
